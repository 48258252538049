import React from "react";

function TermsOfServices() {
  return (
    <>
      <div className="w-fit mt-8 mr-2 overflow-hidden ml-6 ">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Terms Of Services
        </h1>
        <p className="text-base font-semibold leading-7 text-indigo-600">
          Last updated: 17-Nov-2023
        </p>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            1.Introduction
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. Welcome to www.reteach.co.in ("Website") and Reteach Web &
            Application ("Application"), a platform that brings together student
            engagement, content delivery and administration tools in one place
            and is focused on helping educators and institutes go online to
            support a blended learning approach and digital learning solutions
            through video conferencing and related services for digital learning
            management ("Reteach Service") (Website and Application are
            hereinafter collectively referred to as the "Platform") owned and
            managed by Reteach Edutech Private Limited and its affiliates
            ("Company"). The Company also acts as a facilitator for school fee
            financing through its partnerships with various institutions that
            offer, either directly or indirectly, financing solutions for Users
            ("Lending Facilitators") and also provides services of Electronic
            National Automatic Clearing House ("eNACH") auto fee payment set
            ups, payment gateway services, multi-account payment settlements,
            and POS machines through financial technology companies ("Phonepay
            Service"). These Terms of Service ("Terms"/" Terms of Service")
            along with the privacy policy of the Platform ("Privacy Policy") and
            other terms and conditions of the Platform, govern your visit and
            use of the Platform for any of the services including Reteach
            Service and/or Phonepay Service (collectively referred to as "
            Services") offered through the Platform(s) as operated by the
            Company.
            <br /> ii. In this Terms of Services "we" , "our" and "us" refers to
            the Company and "you" , "your" and/or "Users" refers to the user of
            the Platform. Our Platform is for the Users intending to avail the
            Service, however, the Services provided on the Platform are not
            being offered to or intended to be used by the residents of European
            Union, United Kingdom and United States of America. If you are
            resident of India and under the age of 18, or if you are resident of
            any other country and are considered to be of any age determined for
            use of internet services but are less than the contractual age
            determined by the applicable laws of such country, you and your
            parent or legal guardian should review this Terms of Service to make
            sure that you and your parent or legal guardian understands and
            agrees to it, and by using the Platform, you confirm to us that your
            use of the Platform is with the permission of your parent or legal
            guardian, and further, if required, you and your parent or legal
            guardian shall perform or undertake such activities which will
            entitle you to enter into a legally binding and enforceable
            agreement with the Company. If you are availing the Service on
            behalf of an organisation or any legal entity as admin user ("Admin
            User"), you represent and warrant that you have the actual authority
            to agree to these Terms on behalf of such organisation and/or legal
            entity. By using this Website and/or Application or accepting this
            Terms of Services, you and your parent or legal guardian represent
            and warrant to the Company that you or your parent or legal guardian
            are 18 years of age or older, and that you have the right, authority
            and capacity to use the Website and/or Application and agree to and
            abide by this Terms of Services. The User of the Phonepay Service
            also represents and warrants that it accepts the terms of services
            (as amended from time to time) that may be imposed by the Lending
            Facilitators.
            <br /> iii. This Terms of Services is an electronic record in the
            form of an electronic contract formed under the Information
            Technology Act, 2000, rules made thereunder, and any other
            applicable statutes, as amended from time to time. This Terms of
            Services does not require any physical, electronic or digital
            signature.
            <br /> iv. Please read these Terms of Services carefully. By
            accessing and utilising the Services on the Platform, you indicate,
            agree and acknowledge that you understand and consent to this Terms
            of Service, our Privacy Policy and any other terms and conditions of
            the Platform (hereinafter collectively referred to as the " User
            Agreements"), and to the collection and use of information in
            accordance with this Terms of Service.
            <br /> v. Your use of the Services on behalf of your organization
            shall also be governed by terms of the User Agreements.
            Additionally, the use of the Services is subject to the provisions
            and validity of order form and/or software services agreement, if
            any, executed by your organisation with the Company.
            <br /> vi. If you do not agree with (or cannot comply with) the User
            Agreements, then you may not use the Services, but please let us
            know by emailing at care@reteach.in so we can try to find a
            solution. These Terms apply to all visitors, users and others who
            wish to access or use Services.
            <br /> vii. The User expressly agrees and acknowledges that these
            Terms of Services and Privacy Policy are co-terminus in nature and
            that expiry/termination of either one will lead to the termination
            of the other. Further, the Platform provides access rights of the
            Services for a subscription/service period mentioned in the order
            form and/or software services agreement executed between the
            organisation authorising you and the Company ("Subscription
            Period"). Upon termination or expiry of Subscription Period, access
            to all or part of the Services available to User accounts and Admin
            User accounts associated with such organisation may also be
            terminated
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            2.System Requirements
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. Use of the Services requires one or more compatible devices,
            internet access, and certain software, and may require obtaining
            updates or upgrades from time to time. Because use of the Services
            involves hardware, software, and Internet access, your ability to
            access and use the Services may be affected by the performance of
            these factors. High speed internet access is recommended. You
            acknowledge and agree that such system requirements, which may be
            changed from time to time, are your responsibility.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            3.User License for Services
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. The Company will provide the Services and standard updates to the
            Services that are made generally available by the Company during the
            term of your use of the Platform. The Company may, at its sole
            discretion, discontinue the Services or modify the features of the
            Services from time to time without prior notice.
            <br /> ii. Subject to the terms of the User Agreements, the Company
            grants a non-exclusive, non-transferable, non-sublicensable right
            and license to access and use the Platform and the Service(s) solely
            for your professional (commercial or otherwise including school fee
            financing for the Users) or educational purposes, strictly in
            accordance with these Terms of Services (including without
            limitation any applicable service-specific terms), User Agreements
            and all applicable scope of use descriptions.
            <br /> iii. The Company may enable and/or tie-up with the
            organisation and other educational institutions to provide the
            Services to their students through the Platform. In such an event,
            the User shall be bound by the Terms of Services of Teachmint as
            well as those of such organisation and other educational
            institutions.
            <br /> iv. The Company may also provide and/or facilitate
            third-party hardware like attendance machine, GPS machine,
            interactive flat panels, audio-visual devices, content hard-drive,
            etc. for which, customer will be bound to terms and conditions of
            such hardware provider, however the Company shall not be held liable
            for any issues arising out of the use of such third-party hardware
            and any claims and actions shall be made directly against the
            hardware provider.
            <br /> v. The Company may give different access rights to Users
            based on the modules subscribed by their institute and/or as
            regulated by the Admin User. Consequently, any data generated by a
            User on the Platform will be based on their access rights and usage.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            4.User Accounts
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. When you create an account with us, you guarantee that you are
            above the age of 18 years, and that the information you provide us
            is accurate, complete, and current at all times. Inaccurate,
            incomplete, or obsolete information may result in the immediate
            termination of your account on the Platform.
            <br /> ii. In case the Service needs to be used by a person who does
            not fulfill the age requirements mentioned above (as may be revised
            as per applicable laws from time to time) and is desirous of
            registering on the Platform i.e., if a User is a minor/child, then
            the account must be created by the parents or legal guardian who has
            agreed to these Terms of Services, and the User may use the Platform
            with the consent of, and under the supervision of, their parent or
            legal guardian. Accordingly, in such a case, the parent or legal
            guardian must agree to this Terms of Services at the time of their
            registration on the Platform. In the event a person below the age of
            18 years utilizes the Service, it is assumed that he/she has
            obtained the consent of the parents or legal guardian and such use
            is made enabled by the parents or legal guardian, and that such
            usage by the minor/child User is under the supervision of their
            parent or legal guardian. The Company will not be responsible for
            any consequence that arises as a result of misuse of any kind that
            may occur by virtue of any person registering for the Service.
            <br /> iii. Users need to sign up for a user account by providing
            all required information in order to access or use the Services. We
            recommend that you sign up for user accounts by providing the
            assigned institutional email address by your organization. The Users
            may verify and authenticate the registration of their account
            through email id/mobile number OTP verification. You agree to: (a)
            provide true, accurate, current and complete information about
            yourself as prompted by the sign-up process; and (b) maintain and
            promptly update the information provided during sign up to keep it
            true, accurate, current, and complete. If you provide any
            information that is untrue, inaccurate, outdated, or incomplete, or
            if the Company has reasonable grounds to suspect that such
            information is untrue, inaccurate, outdated, or incomplete, the
            Company may terminate your user account and refuse current or future
            use of any or all of the Services.
            <br /> iv. You are responsible for maintaining the confidentiality
            of your account and/or password, including but not limited to the
            restriction of access to your computer and/or account. You agree to
            accept responsibility for any and all activities or actions that
            occur under your account and/or password, whether your password is
            with our Service or a third-party service. You must notify us
            immediately upon becoming aware of any breach of security or
            unauthorized use of your account.
            <br /> v. You may not use as a username the name of another person
            or entity or that is not lawfully available for use, a name or
            trademark that is subject to any rights of another person or entity
            other than you, without appropriate authorization. You may not use
            as a username any name that is offensive, vulgar or obscene.
            <br /> vi. Your organization's administrator may authorise your user
            account through the panel on the Admin User account and/or through
            other such modes enabled on the Platform. Towards this, the Admin
            User might also require you to follow additional rules and may be
            able to access or disable your user account, at its sole discretion.
            <br /> vii. We reserve the right to refuse service, terminate
            accounts, remove or edit content, or cancel orders in our sole
            discretion.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            5.Communications
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. When you use the Services or send emails or other data,
            information or communication to the Platform you agree and
            understand that you are communicating with the Company through
            electronic modes and other telecommunication modes and by using our
            Service, you agree to subscribe to newsletters, marketing or
            promotional materials and other information we may send via any and
            all electronic, digital and other telecommunication modes. However,
            you may opt out of receiving any, or all, of these communications
            from us by following the unsubscribe link or by emailing at
            care@reteach.co.in
            <br /> ii. The Company may, based on any form of access to the
            Application (including free download/trials) or Services or Website
            or registrations through any source whatsoever, contact the User
            through WhatsApp, SMS, email, and call, to give information about
            its products as well as notifications on various important updates
            and/or to seek permission for demonstration of its products. The
            User expressly grants such permission to contact him/her through
            telephone, WhatsApp, SMS, e-mail and holds the Company indemnified
            against any liabilities including financial penalties, damages,
            expenses in case the User's mobile number is registered with Do not
            Call (DNC) database. By registering yourself, you agree to make your
            contact details available to Our employees, associates and partners
            so that you may be contacted for education information and
            promotions through telephone, WhatsApp, SMS, email, etc
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            6.Phonepay Service Use Terms:
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. The institute hereby represents and warrants that it holds all
            the valid and subsisting licenses, permits and consents required for
            the conduct and operation of its business from appropriate competent
            authorities and shall comply with all applicable laws and
            regulations at all times.
            <br /> ii. You understand that the Company may facilitate fee
            financing options to Users from its Lending Facilitators, to enable
            such Users to meet their fee obligations with the institute. You
            understand and acknowledge that the Company merely offers a
            technology platform i.e. the portal through which information on
            such financing can be conveniently accessed/ used.
            <br /> iii. To avail the financing, the Lending Facilitators may
            seek your ‘know your customer' ("KYC") documents/ information. The
            Lending Facilitators have authorised the Company to collect your KYC
            documents/ information on their behalf as and when necessary and you
            agree to submit the same to the Company for any verification. You
            are responsible to ensure that your KYC details with the Company
            and/or Lending Facilitators are current and accurate. You hereby
            authorise the Company to share your KYC documents/information for
            the purpose of availing the Teachpay Services. The Company and you
            acknowledge and understand that collection and retention of such KYC
            documents/information shall be subject to the Company's Privacy
            Policy as may be amended from time to time.
            <br /> iv. The lending facilities may be used through multiple form
            factors, as enabled for you by Lending Facilitators or the Company,
            including online payment or bank transfer, etc. If you opt for any
            of these features, then additional KYC or such other requirements
            may be placed on you.
            <br /> v. You agree that you shall fully read and understand the
            terms of use of the financing or such other documentation (including
            loan agreements) entered into between you and the Lending
            Facilitator, before availing the financing. It is to be noted that
            availability of the financing option is not a matter of right upon
            subscribing to the Teachpay Service. The Lending Facilitator(s)
            shall have the discretion to determine your eligibility to avail the
            financing based on the information and documents provided by you
            through the Company or directly to the Lending Facilitator for
            availing the financing. There shall be no recourse to the Company
            for such decisions made by the Lending Facilitators.
            <br /> vi. Towards providing dashboard and monitoring services, the
            Company shall maintain necessary records in respect of the financing
            availed by you and such other information as required and permitted
            under the applicable laws. You agree that such records and/or
            information shall be sufficient proof of the financing, which you
            shall not dispute.
            <br /> vii. You further confirm that the Lending Facilitator may
            directly deposit the financing availed by you with the institute and
            not necessarily into Your bank account. viii. You shall use the
            financing only for purposes permitted by the Lending Facilitator(s)
            and in accordance with applicable laws. ix. For any repayment
            towards the financing or other applicable charges, you have
            authorised the Lending Facilitator and/or any of their service
            providers to collect such amounts from you as may be due to them
            from time to time and take all steps necessary to collect such
            payments. x. An institute shall, upon cancellation (of any reason)
            of the Services to the customer availing financing options through
            the Teachpay Service, immediately inform the Company of such refund
            and manually refund such amount to the Lending Facilitator(s) in T+2
            days. In the event the institute is unable to transfer the refund
            amount immediately, an interest of 2% per month shall be levied on
            such refund amount until the date of full clearance. xi. Where
            applicable, the institute shall also be liable to pay any subvention
            charges at the rates mentioned in Clause 6(x) above on a pro-rata
            basis for the tenure that the financing is utilized by the
            institute/user. xii. You hereby understand and agree that any and
            all information shared/accessible to you by way of your subscription
            to the Teachpay Service, including but not limited to data relating
            to the Lending Facilitators are proprietary and/or confidential in
            nature. Accordingly, you hereby undertake that you shall not,
            without the prior written consent of the Lending Facilitator(s) use
            or disclose such confidential information to any third-party. xiii.
            The corresponding institutes shall be solely responsible and liable
            for any claim, demands, conflicts, disputes arising between the
            User/customer and the institute. The Lending Facilitators shall not
            be made a party to any such claim which is strictly between the User
            and the institute. xiv. Notwithstanding any other provisions
            contained herein, in no event shall any of the Lending Facilitators
            be liable to you for any direct, indirect, consequential, or similar
            damages of any kind whatsoever.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            7.Fees and Charges
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. The Company reserves the right to charge fees from the User for
            providing various services on the Platform and change its policies
            from time to time. The Company may at its sole discretion introduce
            new services and modify some or all of the existing services offered
            on the Platform. In such an event the Company reserves the
            unrestricted and discretionary right to change, rearrange, add or
            delete services offerings, the selections in those offerings,
            prices, and any other service may offer, at any time and
            accordingly, reserves, the right to introduce fees for the new
            services and/or for some or all of the existing services on the
            Platform, as the case may be. Changes to the fee and related
            policies shall automatically become effective immediately once
            implemented on the Platform. You shall be solely responsible for
            compliance of all applicable laws including those in India for
            making payments to the Company. The Company will endeavor to notify
            you of any such change and its effective date.
            <br /> ii. Pricing / typographical error: If the Company comes
            across any typographic errors with respect to pricing or services
            information, the Company shall have the right to rectify the same or
            cancel the order(s) and refund monies, if any, collected from the
            customer within 90 (ninety) business days of such corrective action
            taken. It is hereby clarified that in the event of such pricing or
            typographical error, the Company shall in no event be responsible to
            compensate the User for any loss, or against any liability caused to
            the User
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            8.Contests, Sweepstakes and Promotions
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. Any contests, sweepstakes or other promotions (collectively, "
            Promotions") made available through the Services may be governed by
            rules that are separate from these Terms of Service. If you
            participate in any Promotions, please review the applicable rules as
            well as our Privacy Policy. If the rules for a Promotion conflict
            with these Terms of Service, rules for Promotion will prevail.
            <br /> ii. The Company may conduct competitions from time to time
            and you agree to allow the Company to use the submissions by you
            including but not limited to videos, written content, craft work
            etc. for promotional purposes. All rights will be reserved by the
            Company for usage of such submissions by you. You additionally
            permit the Company to use every kind of information submitted by you
            like photos, videos etc. for promotional campaigns as it so pleases
            and you will cease to have any rights on the same once your
            submissions are made to the Company.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">9.Content</span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. Our Service allows you to post, link, store, share and otherwise
            make available certain information, text, graphics, audio, videos,
            or other material ("Content"). You are responsible for Content that
            you post on or through the Service, including its legality,
            reliability, and appropriateness.
            <br /> ii. By posting Content on or through the Platform, you
            represent and warrant that: (i) Content is yours (you own it) and/or
            you have the right to use, distribute it and the right to grant us
            the rights and license as provided in these Terms, and (ii) that the
            posting of your Content on or through the Platform does not violate
            the privacy rights, publicity rights, copyrights, contract rights or
            any other rights of any person or entity.
            <br /> iii. You shall not post, host, display, upload, modify,
            publish, transmit, store, update or share any Content or information
            on the Platform that: ■ belongs to another person and to which you
            do not have any right to; or ■ that is grossly harmful, harassing,
            blasphemous, defamatory, libellous, obscene, pornographic,
            paedophilic, libellous, invasive of another's privacy, including
            bodily privacy, hateful, insulting or harassing on the basis of
            gender, religiously, racially or, ethnically objectionable,
            disparaging, relating or encouraging money laundering or gambling,
            or otherwise unlawful in any manner whatever, or unlawfully
            threatening or unlawfully harassing or misleading in any way; or
            promoting any such illegal activities; or otherwise inconsistent
            with or contrary to the laws in force; or ■ is patently offensive to
            the online community, such as sexually explicit content, religiously
            offensive content or content that promotes obscenity, paedophilia,
            racism, bigotry, hatred or physical harm of any kind against any
            group or individual; or ■ harasses or advocates harassment of
            another person; or involves the transmission of "junk mail,""chain
            letters," or unsolicited mass mailing or "spamming"; or ■ infringes
            upon or violates any third party's rights, including without
            limitation unauthorized disclosure of any person's personal
            information e.g. a person's name, email address, physical address or
            phone number or rights of publicity; or ■ infringes any patent,
            trademark, copyright, or other proprietary rights or third party's
            trade secrets or rights of publicity or privacy or shall not be
            fraudulent; or ■ promotes an infringement of, or illegal or
            unauthorized copy of another person's copyrighted work; or ■
            provides material that exploits people in a sexual, violent, or
            otherwise inappropriate manner or solicits personal information from
            anyone; or ■ contains video, photographs, or images of another
            person without his or her express written consent and permission or
            the permission or the consent of his/her guardian in the case of
            minor; or ■ refers to any website or URL that, in the sole
            discretion of the Company contains material that is inappropriate
            for the Platform, or URL of any other website contains content that
            would be prohibited or violates the letter or spirit of these Terms
            of Service; or ■ tries to gain unauthorized access or exceeds the
            scope of authorized access to the Platform or to profiles, blogs,
            communities, account information, bulletins or other areas of the
            Platform or solicits passwords or personal identifying information
            for commercial or unlawful purposes from other Users; or ■ intends
            to advertise or engage in commercial activities and/or sales without
            prior written consent of the Company or intends to mislead, defraud
            or cheat any person. Throughout these Terms of Service, the
            Company's prior written consent" means a communication coming from
            the Company's legal personnel, specifically in response to Your
            request, and specifically addressing the activity or conduct for
            which you seek authorization; or ■ interferes with another User's
            use and access to the Platform; or ■ is harmful to child or harms
            minors in any way; or ■ violates any law for the time being in
            force; or ■ deceives or misleads the reader/ addressee/ Users about
            the origin of such messages or knowingly and intentionally
            communicates any information which is patently false or misleading
            in nature but may reasonably be perceived as a fact, or grossly
            offensive or menacing in nature; or impersonate another person; or ■
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource; or contains virus, malware or other
            computer programming routines that may damage, detrimentally
            interfere with, diminish value of, surreptitiously intercept or
            expropriate any system, data or personal identifiable information;
            or ■ threatens the unity, integrity, defence, security or
            sovereignty of India, friendly relations with foreign states, or
            public order or causes incitement to the commission of any
            cognizable offence or prevents investigation of any offence or is
            insulting to any other nation; or ■ is patently false and untrue,
            and is written or published in any form, with the intent to mislead
            or harass a person, entity or agency for financial gain or to cause
            any injury to any person; or ■ shall not create liability for us or
            cause us to lose (in whole or in part) the services of our ISPs or
            other suppliers.
            <br /> iv. The Company is under no obligation to examine or verify
            any Content posted on the Platform by you, and the Company assumes
            no responsibility or liability relating to any such Content on the
            Platform nor does the Company assumes or shall assume responsibility
            or liability for breach of any of your obligation(s) under these
            Terms of Service. Notwithstanding the above, the Company has the
            right but not the obligation to monitor, edit, delete the Content
            provided by users and may decline to accept and/or remove any
            Content that contain any information inconsistence with these Terms
            of Service. Any Content uploaded by you shall be subject to relevant
            laws and these Terms of Service and may be disabled, or and may be
            subject to investigation under appropriate laws. Furthermore, if you
            are found to be in non-compliance with the laws and regulations,
            these Terms, or the User Agreement, the Company may terminate your
            account/block your access to the Platform and we reserve the right
            to remove any non- compliant Content uploaded by you and/or claim
            indemnity on account of such non-compliance. We reserve the right to
            terminate the account of anyone found to be infringing any
            intellectual property right of any other person or violating any
            applicable laws or these Terms of Service. You specifically agree
            that the Company shall not be responsible for unauthorized access to
            or alteration of your transmissions or data, any material or data
            sent or received or not sent or received through the Platform.
            Further, under no circumstances, the Company shall be liable for any
            unlawful act of the User or its affiliates, relatives, employees,
            agents including but not limited to misuse of any data, unfair trade
            practices, fraud, cyber squatting, hacking and other cyber crimes.
            <br /> v. You retain any and all of your rights to any Content you
            submit, post or display on or through the Platform and you are
            responsible for protecting those rights. We take no responsibility
            and assume no liability for Content you or any third-party posts on
            or through the Platform. However, by posting Content using Service
            you grant us non-exclusive, worldwide, perpetual, irrevocable,
            royalty-free, sub-licensable (through multiple tiers) right and
            license to use, modify, publicly perform, publicly display,
            reproduce, distribute and re-distribute such Content on and through
            Service. You agree that this license includes the right for us to
            make your Content available to other users of the Platform and the
            Service, who may also use your Content subject to these Terms. You
            also acknowledge that owing to the manner in which the Platform is
            used, your Content may be available to other Users on the Platform.
            <br /> vi. In addition, any data, information, material and other
            content found on or through the Platform are the property of the
            Company or licensed to the Company. You may not distribute, modify,
            transmit, reuse, download, repost, copy, or use said data,
            information, material or other content, whether in whole or in part,
            for commercial purposes or for personal gain, without express
            advance written permission from us.
            <br /> vii. You understand that the Company has the right at all
            times to disclose any information (including the identity of the
            User providing information or materials on the Platform) as
            necessary to satisfy any law, regulation or valid governmental
            request, or in response to any court order or summons. In addition,
            the Company can (and you hereby expressly authorize us to) disclose
            any information about you to law enforcement or other government
            officials, as we, in our sole discretion, believe necessary or
            appropriate in connection with the investigation and/or resolution
            of possible crimes, especially those that may involve personal
            injury
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            10. Use of the Platform and the Services
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. You may use the Platform and the Services only for lawful
            purposes and in accordance with these Terms of Services.
            <br /> ii. You hereby agree not to use the Platform and the
            Services: ■ In any way that violates any applicable national or
            international law or regulation; ■ For the purpose of exploiting,
            harming, or attempting to exploit any person or harm minors in any
            way by exposing them to inappropriate content or otherwise; ■ To
            transmit, or procure the sending of, any advertising or promotional
            material, including any "junk mail", "chain letter,""spam," or any
            other similar solicitation; ■ To impersonate or attempt to
            impersonate Company, a Company employee, another user, or any other
            person or entity; ■ In any way that infringes upon the rights of
            others, or in any way is illegal, threatening, fraudulent, or
            harmful, or in connection with any unlawful, illegal, fraudulent, or
            harmful purpose or activity; or ■ To engage in any other conduct
            that restricts or inhibits anyone's use or enjoyment of Service, or
            which, as determined by us, may harm or offend Company or users of
            Service or expose them to liability.
            <br /> iii. Additionally, you agree not to: ■ Use Service in any
            manner that could disable, overburden, damage, or impair Service or
            interfere with any other party's use of Service, including their
            ability to engage in real time activities through Service; ■ Use any
            "deep-link" , "page-scrape" , "robot" , "spider" , or other
            automatic device, process, program or means to access the Platform
            and/or the Service for any purpose, including monitoring or copying
            any of the material on the Platform; ■ Use any manual process to
            monitor or copy any of the material on Service or for any other
            unauthorized purpose without our prior written consent; ■ Use for
            any purpose that is unlawful or otherwise prohibited by these Terms
            of Services, or for other activity which infringes the rights of the
            Company or others; ■ Use any device, software, or routine that
            interferes with the proper working of the Platform; ■ Introduce any
            viruses, trojan horses, worms, logic bombs, or other material which
            is malicious or technologically harmful; ■ Attempt to gain
            unauthorized access to, interfere with, damage, or disrupt any parts
            of Service, the server on which the Platform is stored, or any
            server, computer, or database connected to Service; ■ Attack the
            Platform via a denial-of-service attack or a distributed
            denial-of-service attack; ■ Take any action that may damage or
            falsify Company rating; ■ In any way decompile, reverse engineer, or
            disassemble any material or content on the Website; or ■ Otherwise
            attempt to interfere with the proper working of the Platform and the
            Service
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            11. Payments
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. The Company may enable payments via third-party payment service
            providers ("PSP") partners and other Lending Facilitators and it
            shall be the User's responsibility not to share the credentials for
            authenticating the payment including the UPI pin or OTP with any
            third-party intentionally or unintentionally. The Company never
            solicits credential information for authenticating the payment such
            as UPI pin or OTP over a call or otherwise. The Company shall not be
            liable for any fraud due to the sharing of such details by the User.
            <br /> ii. While availing any of the payment methods available on
            the Platform, the Company shall not be responsible or assume any
            liability, whatsoever in respect of any loss or damage arising
            directly or indirectly to you due to: ■ Lack of authorisation for
            any transaction; ■ Exceeding the pre-set limit mutually agreed by
            you and between the third-party bank; ■ Any payment issues arising
            out of the transaction; or ■ Decline of transaction for any other
            reasons.
            <br /> iii. The Company will not be responsible for any money
            collected by a third-party by impersonating any representative of
            the Company.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            12. Refund/Cancellation
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. The Company does not support refunds in any form for the payment
            transactions being enabled on the Platform. If the User has entered
            the wrong number/VPA ID, then the Company is not a liable party in
            the transaction
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            13. Analytics
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. Towards optimization of the Services and other offerings on the
            Platform, we may use third-party service providers to collect data
            on and analyse your use of our Services, including but not limited
            to user session lengths, time spent on various sections, login
            frequencies, etc.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            14. Intellectual Property
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. The Platform and its original content (excluding Content provided
            by users), features and functionality, structure, expression, "look
            and feel" , all graphics, user interfaces, visual interfaces,
            photographs, trademarks, logos, sounds, music, artwork and computer
            code ("Company Data") are and will remain the exclusive property of
            the Company and its licensors. The Platform is protected by
            copyright, trademark, and other applicable laws in the jurisdiction
            applicable to the operations of the Company. Our trademarks may not
            be used in connection with any product or service without the prior
            written consent of the Company.
            <br /> ii. Except as expressly provided in these Terms of Services,
            no part of the Platform and no Company Data may be copied,
            reproduced, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted or distributed in any way
            (including "mirroring") to any other computer, server, website or
            other medium for publication or distribution or for any commercial
            use, without the Company's express prior written consent. Company
            Data on the Platform is solely for your personal, limited and
            non-exclusive use. Use of the Company Data on any other web site or
            networked computer environment or use of the Company Data for any
            purpose other than personal, non-commercial use is a violation of
            the copyrights, trademarks, and other proprietary rights, and is
            prohibited.
            <br /> iii. You acknowledge and understand that any intellectual
            property you may have access to by way of availing the Teachpay
            Service are the sole and exclusive property of the Company or the
            corresponding Lending Facilitators and their corresponding
            licensors, and undertake not to use the same without the prior
            written consent of the Company and/or the Lending Facilitators, as
            the case may be.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            15. Copyright Policy
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            5. Copyright Policy i. We respect the intellectual property rights
            of others and expect our users to do the same. The Company may
            terminate in appropriate circumstances the accounts of users who
            infringe or are believed to be infringing the rights of copyright
            holders
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            16. Error Reporting and Feedback
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. You may provide us either directly at care@reteach.co.in or via
            third-party sites and tools with information and feedback concerning
            errors, suggestions for improvements, ideas, problems, complaints,
            and other matters related to our Services ("Feedback"). You
            acknowledge and agree that: (i) you shall not retain, acquire or
            assert any intellectual property right or other right, title or
            interest in or to the Feedback; (ii) Company may have development
            ideas similar to the Feedback; (iii) Feedback shall not contain
            confidential information or proprietary information from you or any
            third party; and (iv) Company is not under any obligation of
            confidentiality with respect to the Feedback. In the event the
            transfer of the ownership to the Feedback is not possible due to
            applicable mandatory laws, you grant Company and its affiliates an
            exclusive, transferable, irrevocable, free-of-charge,
            sub-licensable, unlimited and perpetual right to use (including
            copy, modify, create derivative works, publish, distribute and
            commercialize) Feedback in any manner and for any purpose
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            17. Links To Other Web Sites
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. Our Platform uses third party application program interfaces and
            may contain links to third party web sites or services that are not
            owned or controlled by the Company.
            <br /> ii. The Company has no control over, and assumes no
            responsibility for the content, privacy policies, or practices of
            any third-party web sites or services. We do not warrant the
            offerings of any of these entities/individuals or their websites.
            <br /> iii. YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE
            RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR
            LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF
            OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
            THROUGH ANY SUCH THIRD-PARTY WEB SITES OR SERVICES.
            <br /> iv. WE STRONGLY ADVISE YOU TO READ THESE TERMS OF SERVICE AND
            PRIVACY POLICIES OF ANY THIRD-PARTY WEB SITES OR SERVICES THAT YOU
            VISIT.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            18. Disclaimer of Warranty
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. THE PLATFORM AND SERVICES AND THE COMPANY DATA ARE PROVIDED BY
            THE COMPANY ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE COMPANY
            MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
            IMPLIED, AS TO THE OPERATION OF THE PLATFORM AND THE SERVICES, OR
            THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THE PLATFORM, THEIR CONTENT, AND
            ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
            <br /> ii. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
            COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
            COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
            AVAILABILITY OF THE SERVICES OR ITS CONTENT. WITHOUT LIMITING THE
            FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE
            COMPANY REPRESENTS OR WARRANTS THAT THE PLATFORM, THEIR CONTENT, OR
            ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE
            ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL
            BE CORRECTED, THAT THE SERVICES OR THE PLATFORM THAT MAKES IT
            AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT
            THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM
            WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            <br /> iii. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
            NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
            AND FITNESS FOR PARTICULAR PURPOSE. THE COMPANY WILL NOT BE LIABLE
            FOR ANY LOSSES, DAMAGES OR CLAIMS BY YOU OR ANY THIRD-PARTY IN THIS
            REGARD.
            <br /> iv. IN RESPECT OF THE PHONEPAY SERVICES, THE PLATFORM SHALL
            MERELY FACILITATE THE FINANCING SOLUTIONS FROM THE LENDING
            FACILITATORS AND NOT ACTUALLY PROVIDE OR PARTAKE IN ANY DECISION
            REGARDING THE FINANCING SOLUTIONS. FURTHER, YOU HEREBY IRREVOCABLY
            AUTHORIZE THE LENDING FACILITATOR TO DEBIT ALL PAYMENTS REQUIRED TO
            BE MADE BY YOU HEREUNDER OR OTHERWISE, ON THE DATE DUE, FROM THE
            BANK ACCOUNT DETAILS PROVIDED BY YOU TO THE LENDING FACILITATOR.
            OTHERWISE, YOU WILL BE OBLIGATED TO MAKE SUCH PAYMENTS DIRECTLY TO
            LENDING FACILITATOR SUBJECT TO THE PROVISIONS OFFINANCING
            AGREEMENT(s) ENTERED INTO BETWEEN THE LENDING FACILITATOR AND YOU.
            WE DO NOT ACCEPT AND SHALL NOT BE LIABLE FOR COLLECTIONS OF ANY
            PAYMENT IN ANY WAY OR ANY OTHER METHOD PERTAINING TO TEACHPAY
            SERVICES. ACCORDINGLY, ANY PAYMENT SHALL NOT BE DEEMED TO HAVE BEEN
            MADE UNTIL THE FUNDS COMPRISING SUCH PAYMENT ARE MADE IN THE
            AFORESAID AUTHORIZED MANNER AND HAVE ACTUALLY BEEN RECEIVED BY THE
            LENDING FACILITATOR. FURTHER, ANY PAYMENT MADE BY YOU TO LENDING
            FACILITATOR THROUGH ANY MODE OF PAYMENT EXCEPT AS MENTIONED
            HEREINBEFORE SHALL BE AT YOUR OWN RISK AND THE COMPANY SHALL NOT BE
            LIABLE OR RESPONSIBLE FOR THE SAME. YOU SHALL ADDITIONALLY INDEMNIFY
            US AGAINST ANY LOSS CAUSED TO US OR OUR LENDING FACILITATOR ON
            ACCOUNT OF SUCH UNAUTHORIZED PAYMENT.
            <br /> v. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT
            BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            19. Indemnity and Limitation of Liability
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR LICENSOR,
            OFFICERS, DIRECTORS, EMPLOYEES, LENDING FACILITATORS, OTHER LICENSEE
            AND AGENTS HARMLESS FOR ANY LOSS, DAMAGE, OR CLAIM, HOWEVER IT
            ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES
            OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY,
            WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN
            AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR
            ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, INCLUDING WITHOUT
            LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING
            FROM THESE TERMS AND ANY VIOLATION BY YOU OF ANY CENTRAL, STATE, OR
            LOCAL LAWS, STATUTES, RULES, OR REGULATIONS OR TERMS OF SERVICE, ANY
            ACT OF NEGLIGENCE, FRAUD, FORGERY, DISHONESTY, MONEY LAUNDERING,
            EVEN IF THE COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY
            OF SUCH LOSSES OR DAMAGE OR CLAIMS . EXCEPT AS PROHIBITED BY LAW, IF
            THERE IS LIABILITY FOUND ON THE PART OF THE COMPANY, IT WILL BE
            LIMITED TO THE AMOUNT PAID BY YOU FOR THE PRODUCTS AND/OR SERVICES
            ON THE PLATFORM.
            <br /> ii. UNDER NO CIRCUMSTANCE, INCLUDING, BUT NOT LIMITED TO,
            NEGLIGENCE, SHALL THE COMPANY BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, EXEMPLARY, DAMAGES FOR LOST PROFITS, BUSINESS
            INTERRUPTION AND LOSS OF PROGRAMS OR INFORMATION ARISING OUT OF THE
            USE OF OR INABILITY TO USE THE PLATFORM OR CONSEQUENTIAL DAMAGES
            THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, INCLUDING BUT
            NOT LIMITED TO THE INFORMATION, CONTENT, MATERIALS ON THE PLATFORM,
            OR ANY PART THEREOF. WHILE THE COMPANY SHALL TAKE REASONABLE
            PRECAUTIONS AGAINST SECURITY BREACHES, THE PLATFORM OR INTERNET
            TRANSMISSION IS NOT COMPLETELY SECURE, AND AS SUCH, THE COMPANY
            SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, EXEMPLARY, OR
            CONSEQUENTIAL DAMAGES THAT MAY RESULT FROM UNAUTHORIZED ACCESS,
            HACKING, DATA LOSS, OR OTHER BREACHES THAT MAY OCCUR.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            20. Termination
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. We may terminate or suspend your account and bar access to the
            Platform or any of the Services immediately, without prior notice or
            liability, under our sole discretion, for any reason whatsoever and
            without limitation, including but not limited to a breach of these
            Terms of Services or any other agreement.
            <br /> ii. If you wish to terminate your account, you may simply
            discontinue using Services, subject to the provisions of order
            agreement and/or software services agreement and/or other agreements
            you may have entered into with the Company and/or the organisations
            and/or legal entity including school, college, coaching or
            educational institute.
            <br /> iii. All provisions of these Terms of Services which by their
            nature should survive termination shall survive termination,
            including, without limitation, ownership provisions, warranty
            disclaimers, indemnity and limitations of liability. Notwithstanding
            the foregoing, if you breach these Terms of Services or Privacy
            Policy or other rules and policies, the Company reserves the right
            to recover any amounts due and owing by you or to take strict legal
            action including but not limited to a referral to the appropriate
            police or other authorities for initiating criminal or other
            proceedings against you.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            21. Governing Law
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. These Terms of Services shall be governed and construed in
            accordance with the laws of India, which governing law applies to
            the User Agreement without regard to its conflict of law provisions,
            and any disputes relating to these Terms of Services and User
            Agreements will be subject to the exclusive jurisdiction of the
            courts of Bengaluru, Karnataka, India.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            22. Force Majeure
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            The Company is not liable for any delay in the performance or
            non-performance of any of its obligations hereunder and shall not be
            liable for any loss or damages caused thereby where the same is
            occasioned by any cause whatsoever that is beyond our control
            including but not limited to an act of God, war, civil disturbance,
            governmental or parliamentary restrictions, prohibitions or
            enactments of any kind, or accident or non-availability/ delay in
            transport, any endemic, pandemic, epidemic or outbreak of any
            disease including Covid-19.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            23. Changes to Service
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. We reserve the right to withdraw or amend our Services, and any
            service or material we provide via the Platform, in our sole
            discretion without notice. We will not be liable if for any reason
            all or any part of Service is unavailable at any time or for any
            period. From time to time, we may restrict access to some parts of
            the Platform or the Services, or the entire Platform, to users,
            including registered users
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            24. Amendments to Terms
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. We may amend this Terms of Services at any time by posting the
            amended terms ("Revised Terms") on the same or different webpage
            designated by us for the Terms on the Platform. It is your
            responsibility to review these Terms of Services periodically. Any
            such amendment shall be deemed to be effective in respect of you,
            thirty (30) days from the date such change was posted on this
            webpage (or any other such webpage on the Platform) or otherwise
            communicated to you.
            <br /> ii. Subject to the above, your continued use of the Platform
            following the posting of Revised Terms shall imply your
            acknowledgement of and agreement with the Revised Terms, which shall
            be binding on you.
            <br /> iii. If you do not agree to the Revised Terms, you are no
            longer authorized to use the Platform and the Service
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            25. Waiver and Severability
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. No waiver by the Company of any term or condition set forth in
            these Terms of Service shall be deemed to be a further or continuing
            waiver of such term or condition or a waiver of any other term or
            condition, and any failure of Company to assert any right or
            provision under Terms of Services shall not constitute a waiver of
            such right or provision.
            <br /> ii. If any provision of Terms of Services is held by a court
            or other tribunal of competent jurisdiction to be invalid, illegal
            or unenforceable for any reason, such provision shall be eliminated
            or limited to the minimum extent such that the remaining provisions
            of these Terms of Services will continue in full force and effect.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            26. Assignment
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. The Company may transfer, sub-contract or otherwise deal with its
            rights and/or obligations under these Terms of Services without
            notifying you or obtaining your consent.
            <br /> ii. You shall not transfer, sub-contract or otherwise deal
            with your rights and/or obligations under these Terms of Services
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            27. Acknowledgement
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. BY USING THE PLATFORM OR OTHER SERVICES PROVIDED BY US, YOU
            ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO
            BE BOUND BY THEM.
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white">
            28. Contact Us
          </span>
          <p className="mt-2 text-sm leading-6 text-white">
            i. Please send your feedback, comments, requests for technical
            support by email: care@reteach.co.in
            <br /> ii. In accordance with the Information Technology Act, 2000
            and Information Technology (Intermediary Guidelines and Digital
            Media Ethics Code) Rules, 2021, the name and contact details of the
            Grievance Officer are provided below:
          </p>
          <p>
            {" "}
            <div className="flex items-center">
              <strong className="font-semibold text-indigo-700">
                Name: <br />
              </strong>
              <p className="text-sm leading-6 ml-1 text-white"> V.Dayal</p>
            </div>
            <div className="flex items-center">
              <strong className="font-semibold text-indigo-700">
                Address: <br />
              </strong>
              <p className="text-sm leading-6 ml-1 text-white">
                {" " +
                  `Reteach Edutech Private Limited, 16-A Suman Vihar Heerapura,
                Dhawas Road, Jaipur, Rajasthan -302 021 India, Nearby Green Leaf
                Hotel.`}
              </p>
            </div>
            <div className="flex items-center">
              <strong className="font-semibold text-indigo-700">
                Email: <br />
              </strong>
              <p className="text-sm leading-6 ml-1 text-white">
                {" " + "grievances@reteach.co.in"}
              </p>
            </div>
          </p>
        </div>
        <div>
          <span className="mt-6 text-xl leading-8 text-white"></span>
          <p className="mt-2 text-sm leading-6 text-white"></p>
        </div>
      </div>
    </>
  );
}

export default TermsOfServices;
